import React from 'react'
import { graphql } from 'gatsby'

import ContentHeaderBgImg from '../components/ContentHeaderBgImg'
import SEO from '../components/seo'
import FAQSet from '../components/FAQSet.jsx'

import Layout from '../components/layout'

const FAQ = ({ data }) => {
  const { header } = data.gatsby
  const thumbnails = {
    thumbnailSmall: (header.thumbnailSmall.length ? header.thumbnailSmall[0].url : null),
    thumbnailMedium: (header.thumbnailMedium.length ? header.thumbnailMedium[0].url : null),
    thumbnailLarge: (header.thumbnailLarge.length ? header.thumbnailLarge[0].url : null)
  }

  return (
    <Layout>
      <SEO seomatic={header.seomatic} />
      <ContentHeaderBgImg
        title={header.headline}
        text={header.description}
        meta={header.excerpt}
        thumbnails={thumbnails}
      />
      {header.faqSet.length ? 
          <FAQSet faqSet={header.faqSet} />
          : null}
    </Layout>
  )
}

export const query = graphql`
  query {
    gatsby {
      header: entry(slug: "faq-page") {
        ... on Gatsby_faqPage_faqPage_Entry {
          headline
          description
          excerpt
          seomatic {
            metaTitleContainer
            metaTagContainer
          }
          faqSet {
            ... on Gatsby_faq_faq_Entry {
              title
              richText
              id
            }
          }
          thumbnailLarge {
            url
          }
          thumbnailMedium {
            url
          }
          thumbnailSmall {
            url
          }
        }
      }
    }
  }
`

export default FAQ
